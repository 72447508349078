import React from 'react';
import './styles.scss';

function Button(props) {
  return (
    <a href={props.onClick} className={props.theme} rel="noopener noreferrer" target="_blank">{props.children}</a>
  );
}

export default Button;
