import React from 'react';
import './styles.scss';

function ThreeColImgs(props) {
  const sectionContent = props.content;
  return (
  <section className={props.classNames}>
    <div>
      <img src={sectionContent.img1.imgSrc} alt={sectionContent.img1.alt} />
    </div>
    <div>
      <img src={sectionContent.img2.imgSrc} alt={sectionContent.img2.alt} />
    </div>
    <div>
      <img src={sectionContent.img3.imgSrc} alt={sectionContent.img3.alt} />
    </div>
  </section>
  );
}

export default ThreeColImgs;
