import React from 'react';
import { BrowserRouter as Switch, Route, HashRouter } from 'react-router-dom';


import './App.scss';
import Home from './components/Pages/home/';
import About from './components/Pages/about/';
import Events  from './components/Pages/events/';
import Resources from './components/Pages/resources/';
import Header from './components/Templates/Header/';
import Footer from './components/Templates/Footer/';
import { header } from './content/header.js';
import { footer } from './content/footer.js';


function App() {
  return (
    <HashRouter>
      <Header headerContent={header}  />
        <Route exact path='/' component={Home} />
        <Route path='/about' component={About} />
        <Route path='/resources' component={Resources} />
        <Route path='/events' component={Events} />
      <Footer footerContent={footer} />
    </HashRouter>
  );
}

export default App;
