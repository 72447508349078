import React from 'react';
import './styles.scss';

function Cent1colNoButton(props) {
  const sectionContent = props.content;
  return (
  <section className={props.classNames}>
    <div className={props.innerClass}>
      <h2>{sectionContent.title}</h2>
      <p>{sectionContent.paragraph}</p>
    </div>
  </section>
  );
}

export default Cent1colNoButton;
