import React from 'react';
import './styles.scss';

function SponsorshipItem(props) {
  return (
    <li className="SponsorItem">
      <a href={props.href} >
        <img src={props.imgSrc} alt={props.imgAlt} />
      </a>
    </li>
  );
}

export default SponsorshipItem;
