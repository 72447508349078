import React from 'react';
import './styles.scss';

import SponsorItem from '../../Atoms/SponsorshipItem'

function SponsorshipList(props) {
  const sponsorItems = props.sponsorList;
  const listItems = sponsorItems.map((item, index) =>
    <SponsorItem
      key={index}
      href={item.linkSrc}
      imgSrc={item.imgSrc}
      alt={item.alt} />
  )
  return (
    <ul className="sponsorItems">
      <li><p>In Partnership With:</p></li>
      {listItems}
    </ul>
  );
}

export default SponsorshipList;
