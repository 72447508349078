import React, { useState }  from 'react';
import '../../../fonts.css';
import Hero from '../../Templates/HeroImage/';
import Card from '../../Molecules/Card/';
import Cent1colNoButton from '../../Templates/Cent1colNoButton';
import CardCarousel from '../../Organisms/CardCarousel';
import { db } from '../../../content/resources.js';
import './styles.scss';


export default (props) => {

  const [language] = useState('en');
  const { heroImage, content, resources } = db[language];
  const resourcesMap = resources.map((item, index) =>
    <Card
      key={index}
      imgSrc={item.imgSrc}
      title={item.title}
      content={item.desc}
      buttonOC={item.buttonLink}
      btheme={item.btheme}
      bchildren={item.bText}
    />
  );

  return (
    <>
      <Hero heroContent={heroImage} />
      <Cent1colNoButton classNames='centered titleBlock' content={content} />
      <section className="resourceList">
        {resourcesMap}
      </section>
      <CardCarousel pageContent={'aboutpageCarousel'} />
    </>
  );
}
