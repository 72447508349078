import React, { useState }  from 'react';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from '../../Molecules/Card/';

import { cardb } from '../../../content/carousel.js'
import './styles.scss';

const responsive = {
  ldesktop: {
    breakpoint: { max: 3000, min: 1200 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1200, min: 992 },
    items: 3,
    slidesToSlide: 3,
  },
  tablets: {
    breakpoint: { max: 992, min: 768 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  small: {
    breakpoint: {max: 768, min: 576 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};


function CardCarousel(props) {
  const [language] = useState('en');
  const content = cardb[language];

  const ButtonGroup = ({ next, previous, goToSlide, carouselState }) => {
    if (content[props.pageContent].length > 3) {
    return (
      <div className="custom-button-group greaterThan">
        <button onClick={() => previous()}><img src='img/carousel/left_arrow.png' alt="Left Arrow button" /></button>
        <button onClick={() => next()}><img src='img/carousel/right_arrow.png' alt="Right Arrow button"/></button>
      </div>
    );
  } else {
    return (
      <div className="custom-button-group lessThan">
        <button onClick={() => previous()}><img src='img/carousel/left_arrow.png' alt="Left Arrow button" /></button>
        <button onClick={() => next()}><img src='img/carousel/right_arrow.png' alt="Right Arrow button"/></button>
      </div>
    );
  }
  };

function createSlides(slideData) {

  const slidesList = content[slideData].map((item, index) => {
    return <Card
      key={index}
      imgSrc={item.imgSrc}
      title={item.title}
      content={item.content}
      buttonOC={item.buttonLink}
      btheme={item.btheme}
      bchildren={item.bText}
    />
    }
  );
  return slidesList;
}

  return (
    <Carousel
      arrows={false}
      responsive={responsive}
      renderButtonGroupOutside={true}
      customButtonGroup={<ButtonGroup />}
    >
    {createSlides(props.pageContent)}
    </Carousel>
  );
}

export default CardCarousel;
