const footer = {
  logo: {
    src: 'img/lhl_logo_footer.png'
  },
  navbar: [
    {
      linkSrc: '/about',
      alt: 'lighthouse website',
      linkText: 'About'
    },
    {
      linkSrc: '/events',
      alt: 'lighthouse website',
      linkText: 'Events'
    },
    {
      linkSrc: '/resources',
      alt: 'lighthouse website',
      linkText: 'Resources'
    }
  ],
  socialList: [
    {
      linkSrc: 'https://www.facebook.com/lighthouselabsvancouver',
      imgSrc: 'img/social/fb_footer.png',
      alt: 'Facebook'
    },
    {
      linkSrc: 'https://twitter.com/lighthouse_labs',
      imgSrc: 'img/social/tw_footer.png',
      alt: 'Twitter'
    },
    {
      linkSrc: 'https://www.instagram.com/lighthouselabs',
      imgSrc: 'img/social/ig_footer.png',
      alt: 'Insta'
    },
    {
      linkSrc: 'https://www.linkedin.com/company/lighthouse-labs',
      imgSrc: 'img/social/linkdin_footer.png',
      alt: 'Linkedin'
    }
  ],
  sponsorList: [
    {
      linkSrc:'https://pixelunion.net',
      imgSrc: 'img/sponsors/Pixel_Union_Logo.svg',
      alt: 'Pixel Union'
    },
    {
      linkSrc: 'https://mentoratquebec.org/mois-du-mentorat/forum-du-mentorat-2020/',
      imgSrc: 'img/sponsors/Forum_RGB_Grand.png',
      alt: 'Mentorat Québec'
    },
    {
      linkSrc: 'https://www.reseaum.com/',
      imgSrc: 'img/sponsors/reseaum.png',
      alt: 'Réseau M'
    },
    {
      linkSrc: 'https://workhaus.ca/',
      imgSrc: 'img/sponsors/Workhaus-Logo.png',
      alt: 'Workhaus'
    },
    {
      linkSrc: 'https://bcdevexchange.org/',
      imgSrc: 'img/sponsors/BCDevExchange-logo.png'
    },
    {
      linkSrc: 'https://www.futurpreneur.ca/en/',
      imgSrc: 'img/sponsors/futurpreneur-logo.png'
    }
  ]
}

export {footer}
