import React, { useState }  from 'react';
import '../../../fonts.css';
import './styles.scss';

import Hero from '../../Templates/HeroImage/';
import Centered1col from '../../Templates/Centered1col/';
import CardCarousel from '../../Organisms/CardCarousel/';
import { db } from '../../../content/home.js';
import Mailchimp from 'react-mailchimp-form'


export default (props) => {
  const [language] = useState('en');
  const { heroImage, aboutSection, whyJoinUs } = db[language];
  return (
    <>
      <Hero heroContent={heroImage} />
      <Centered1col classNames='Centerd1Col centered' innerClass='SkinnyContainer' content={aboutSection} />
      <section className="videoWrap">
        <iframe title="Mentorship Month" src="https://www.youtube.com/embed/ugwm8wJxg3Y" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </section>
      <section className="why_join_us--Wrap">
        <div className="Container">
          <div>
            <h2>{whyJoinUs.title}</h2>
            <p>{whyJoinUs.content}</p>
          </div>
          <div>
          <Mailchimp
             action='https://lighthouselabs.us10.list-manage.com/subscribe/post?u=e52f4473cc66c663ef6a0b1f4&amp;id=5562be87ae'
             fields={[
               {
                 name: 'EMAIL',
                 placeholder: 'Email',
                 type: 'email',
                 required: true
               }
             ]}
             messages = {
                {
                  sending: "Sending...",
                  success: "Thank you for subscribing!",
                  error: "An unexpected internal error has occurred.",
                  empty: "You must write an e-mail.",
                  duplicate: "Too many subscribe attempts for this email address",
                  button: "Sign Up!"
                }
              }
              className='mailChimp'
             />
          </div>
        </div>
      </section>
      <CardCarousel pageContent={'homepageCarousel'} />
    </>
  );
}
