const db = {
  en: {
    heroImage: {
      imgSrc: 'img/home/Hero_image_date.png',
      alt: 'Mentorship Month Hero'
    },
    aboutSection: {
      title:'Passing The Torch',
      paragraph: 'In a rapidly changing workforce, lifelong learning is the key to continued career advancement. Mentorship is at the core of this: building connections between growth-minded professionals so they can share knowledge, impart skills, and build a roadmap to future career success.',
      button: {
        theme: 'primary',
        onClick: '#/about',
        buttonText: 'Learn More'
      }
    },
    whyJoinUs: {
      title: 'Keep In Touch',
      content: 'Sign up to find out when we add new content, resources, or mentorship events in your community.',
    }
  }
}

export { db }
