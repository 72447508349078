import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import NavbarList from '../../Molecules/NavbarList/';
import SocialList from '../../Molecules/SocialList/'

function Navbar({navbarContent}) {
  return (
    <nav>
      <Link to={'/'} className="nav-link">
        <img className="Logo" alt="" src={navbarContent.logo.src} />
      </Link>
      <div className="spacer"></div>
      <NavbarList navBarItems={navbarContent.navbar} />
      <SocialList socialItems={navbarContent.socialList} />
    </nav>
  );
}

export default Navbar;
