import React, { Component }  from 'react';

import '../../../fonts.css';

import Hero from '../../Templates/HeroImage/';
import FullWidth2col from '../../Templates/FullWidth2col/';
import Cent1colNoButton from '../../Templates/Cent1colNoButton/';
import ThreeColImgs from '../../Templates/3colImages/';
import Centered2Col from '../../Templates/Centered2Col';
import CardCarousel from '../../Organisms/CardCarousel/';
import FullWidth2colButton from '../../Templates/FullWidth2colButton';
import { db } from '../../../content/about.js';
import './styles.scss';


export default class About extends Component{
  constructor(props) {
    super(props)
    this.state = {
      language: 'en'
    }
  }

  render () {
    const { heroImage, aboutSection, mentorGrow, threeColImgs, centered2Col, lighthouseLabs} = db[this.state.language];
    return (
      <>
        <Hero heroContent={heroImage} />
        <FullWidth2col content={aboutSection} classNames='FullWidth2col'/>
        <Cent1colNoButton classNames='colAbout centered' innerClass='SkinnyContainer' content={mentorGrow} />
        <ThreeColImgs classNames='threeColImgs' content={threeColImgs} />
        <Centered2Col content={centered2Col} classNames={'centered2Col'} />
        <CardCarousel pageContent={'aboutpageCarousel'} />
        <FullWidth2colButton classNames={'FullWidth2colButton'} content={lighthouseLabs} />
      </>
    );
  }
}
