import React from 'react';
import './styles.scss';
import Button from '../../Atoms/Button'

function FullWidth2colButton(props) {
  const sectionContent = props.content;
  return (
  <section className={props.classNames}>
    <div className="twoCol__Left">
      <img src={sectionContent.logo.imgsrc} alt={sectionContent.logo.alt} />
    </div>
    <div className={props.innerClass}>
      <p>{sectionContent.paragraph}</p>
      <Button theme='primary' onClick={sectionContent.button.onClick}
>Learn More</Button>
    </div>
  </section>
  );
}

export default FullWidth2colButton;
