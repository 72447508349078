const db = {
  en: {
    heroImage: {
      imgSrc: 'img/resources/Resource_hero_image.jpg',
      alt: 'Mentorship Month Hero'
    },
    content: {
      title: 'Mentorship Resources',
      paragraph: 'These custom-built resources are designed to help you build your mentorship skills and establish a mentorship culture within your workspace.'
    },
    resources: [
      {
        imgSrc:'resources/1sc.png',
        title: 'Mentorship is a gateway to help people reach their full potential',
        desc: '',
        buttonLink: '/resources/1.pdf',
        btheme: "primary",
        bText: "Learn More"
      },
      {
        imgSrc:'resources/2sc.png',
        title: 'Create A Mentorship Culture',
        desc: '',
        buttonLink: '/resources/2.pdf',
        btheme: "primary",
        bText: "Learn More"
      },
      {
        imgSrc:'resources/3sc.png',
        title: 'Preparing yourself for Mentorship',
        desc: '',
        buttonLink: '/resources/3.pdf',
        btheme: "primary",
        bText: "Learn More"
      }
    ]
  }
}

export { db }
