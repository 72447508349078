import React from 'react';
import './styles.scss';
import Button from '../../Atoms/Button'

function Centered1col(props) {
  const sectionContent = props.content;
  const buttonInfo = sectionContent.button;
  return (
  <section className={props.classNames}>
    <div className={props.innerClass}>
      <h2>{sectionContent.title}</h2>
      <p>{sectionContent.paragraph}</p>
      <Button
        theme={buttonInfo.theme}
        onClick={buttonInfo.onClick}
      >
        {buttonInfo.buttonText}
      </Button>
    </div>
  </section>
  );
}

export default Centered1col;
