import React from 'react';
import './styles.scss';

function FullWidth2col(props) {
  const sectionContent = props.content;
  return (
  <section className={props.classNames}>
    <div className="twoCol__Left">
      <img src={sectionContent.logo.imgsrc} alt={sectionContent.logo.alt} />
    </div>
    <div className={props.innerClass}>
      <h2>{sectionContent.title}</h2>
      <p>{sectionContent.paragraph}</p>
    </div>
  </section>
  );
}

export default FullWidth2col;
