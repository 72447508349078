import React, { useState }  from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import '../../../fonts.css';
import Hero from '../../Templates/HeroImage/';
import Event from '../../Molecules/Event/';
import { db } from '../../../content/events.js';
import './styles.scss';

const createEventList = function(events){
    var sortedEvents = events.sort(function(a,b){
      var dateA = new Date(a.date[0] + " " + a.date[1] + " 2019"); 
      var dateB = new Date(b.date[0] + " " + b.date[1] + " 2019");
      return dateA < dateB ? -1 : 1;
    });

    return sortedEvents.map((item, index) =>
      <Event key={index} indDetails={item} />
    )
};

export default (props) => {
  const [language] = useState('en');
  const { heroImage, events } = db[language];
  const torontoEvents = events.Toronto;
  const montrealEvents = events.Montreal;
  const victoriaEvents = events.Victoria;
  const vanEvents = events.Vancouver;

  const allEvents = torontoEvents.concat(montrealEvents, victoriaEvents, vanEvents);
  const allEventsMap = createEventList(allEvents);

  function cityTab(events){

    const cityList = Object.keys(events);
    const tabList = cityList.map((item, index) => {
        if (events[item].length > 0) {
          return <Tab key={index} >{item}</Tab>
        } else {
          return '';
        }
      }
    );
    return tabList;
  }

  return (
    <>
      <Hero heroContent={heroImage} />
      <section className="eventNavList__Wrap">
        <Tabs>
          <div className="tabsList__Wrap">
            <TabList>
              <Tab>All Events</Tab>
              {cityTab(events)}
            </TabList>
          </div>
          <TabPanel className='all__Events'>
            {allEventsMap}
          </TabPanel>
          {Object.keys(events).map((propKey, index) => {
            return (<TabPanel key={index}>
              {Object.keys(events[propKey]).map((item, index) => {
                return (<Event key={item} indDetails={events[propKey][index]} />)
              })}
            </TabPanel>)
          })}
        </Tabs>

      </section>
    </>
  );
}
