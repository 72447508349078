import React from 'react';
import './styles.scss';

function Hero(props) {
  const heroContent = props.heroContent
  return (
  <section className="Hero" style={{backgroundImage: "url(" + heroContent.imgSrc }}>
  </section>
  );
}

export default Hero;
