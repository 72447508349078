import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import ReactGA from 'react-ga'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';


ReactGA.initialize('UA-30299155-9');
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.pageview('/');
ReactGA.pageview('/about');
ReactGA.pageview('/events');
ReactGA.pageview('/resources');

// Facebook Pixel Implementations
const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
};
ReactPixel.init('486611734821609', options);

ReactPixel.pageView(); // For tracking page view


ReactDOM.render((
  <BrowserRouter>
    <App />
  </BrowserRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
