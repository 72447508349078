const db = {
  en: {
    heroImage: {
      imgSrc: 'img/about/About_Hero_image.jpg',
      alt: 'Mentorship Month Hero'
    },
    aboutSection: {
      title:'Passing The Torch',
      paragraph: 'In a rapidly changing workforce, lifelong learning is the key to continued career advancement. Mentorship is at the core of this: building connections between growth-minded professionals so they can share knowledge, impart skills, and build a roadmap to future career success.',
      logo: {
        imgsrc: 'img/about/about_logo.png',
        alt: 'Mentorship Month Hero'
      }
    },
    mentorGrow: {
      title: 'Mentorship To Grow Your Career',
      paragraph: 'Mentorship Month is a national initiative created by Lighthouse Labs, with one simple goal: to shine a light on the value of mentorship. Through strategic relationship-building, mentorship helps individuals reach their full potential - in education, professional development, and personal growth. Mentorship Month is a dedicated space to celebrate mentorship and equip Canadian communities with the tools they need to pursue more intentional mentorship.'
    },
    threeColImgs: {
      img1: {
        imgSrc : 'img/about/1.jpg',
        alt: 'about stuff 1'
      },
      img2: {
        imgSrc : 'img/about/2.jpg',
        alt: 'about stuff 2'
      },
      img3: {
        imgSrc : 'img/about/3.jpg',
        alt: 'about stuff 3'
      }
    },
    centered2Col: {
      left: {
        title: 'Resources',
        paragraph: 'Explore our resources to discover how to pursue mentorship, maximize your own mentorship impact, or develop a mentorship culture in your community.',
        button: {
          onClick: '#/resources'
        }
      },
      right: {
        title: 'Events',
        paragraph: 'Discover mentorship events happening in our area - or plan one of your own!',
        button: {
          onClick: '#/events'
        }
      }
    },
    lighthouseLabs: {
      logo: {
        imgsrc: 'img/about/lhl_full_color.png',
        alt: 'Lighthouse Labs Logo'
      },
      paragraph: 'Lighthouse Labs is Canada’s leading technology educator. Our unique mentorship model and community-driven education philosophy equips students and professionals with the skills they need to future-proof their careers in a rapidly changing marketplace.',
      button: {
        onClick: 'https://www.lighthouselabs.ca/about',
      }
    }
  }
}

export { db }
