const header = {
  logo: {
    src: 'img/Logo_menu_bar.png'
  },
  navbar: [
    {
      linkSrc: '/about',
      alt: 'lighthouse website',
      linkText: 'About'
    },
    {
      linkSrc: '/events',
      alt: 'lighthouse website',
      linkText: 'Events'
    },
    {
      linkSrc: '/resources',
      alt: 'lighthouse website',
      linkText: 'Resources'
    }
  ],
  socialList: [
    {
      linkSrc: 'https://www.facebook.com/lighthouselabsvancouver',
      imgSrc: 'img/social/fb.png',
      alt: 'Facebook'
    },
    {
      linkSrc: 'https://twitter.com/lighthouse_labs',
      imgSrc: 'img/social/tw.png',
      alt: 'Twitter'
    },
    {
      linkSrc: 'https://www.instagram.com/lighthouselabs',
      imgSrc: 'img/social/ig.png',
      alt: 'Insta'
    },
    {
      linkSrc: 'https://www.linkedin.com/company/lighthouse-labs',
      imgSrc: 'img/social/linkdin.png',
      alt: 'Linkedin'
    }
  ]
}
export { header }
