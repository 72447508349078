import React from 'react';
import './styles.scss';

import NavbarItem from '../../Atoms/NavbarItem'

function NavbarList(props) {

  const navItems = props.navBarItems;
  const listItems = navItems.map((item, index) =>
    <NavbarItem key={index} link={item.linkSrc} alt={item.alt} text={item.linkText} />
  );
  return (
    <ul className="navBar">
      {listItems}
    </ul>
  );
}

export default NavbarList;
