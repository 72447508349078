import React from 'react';
import './styles.scss';

function SocialIcon(props) {
  return (
    <li className="SocialIcon">
      <a href={props.href} >
        <img src={props.imgSrc} alt={props.imgAlt} />
      </a>
    </li>
  );
}

export default SocialIcon;
