const cardb = {
  en: {
    homepageCarousel: [
      {
        imgSrc: "img/carousel/3.png",
        title: "Why This Shopify Dev Mentors Students At Lighthouse Labs",
        content: "Sanjana found career success at Shopify, but this bootcamp alumni returned to Lighthouse Labs to provide the same mentorship she received as a student.",
        buttonLink: 'https://www.coursereport.com/blog/why-this-web-developer-mentors-lighthouse-labs-students',
        btheme: "primary",
        bText: "Learn More"
      },
      {
        imgSrc: "img/carousel/5.png",
        title: "Why Mentorship Is The Backbone Of Our Bootcamp",
        content: "Lighthouse Labs mentors are not your average TA - they're the catalysts for learning and growth in our bootcamp.",
        buttonLink: 'https://www.lighthouselabs.ca/blog/why-mentorship-is-the-backbone-of-our-bootcamp',
        btheme: "primary",
        bText: "Learn More"
      },
      {
        imgSrc: "img/carousel/2.png",
        title: "Why We're Launching A National Initiative To Celebrate Mentorship",
        content: "Our take on Mentorship Month, a dedicated space to shine the spotlight on mentors and promote more intentional mentorship across Canada.",
        buttonLink: 'https://www.lighthouselabs.ca/blog/why-we-re-launching-a-national-initiative-to-celebrate-mentorship',
        btheme: "primary",
        bText: "Learn More"
      },
    ],
    aboutpageCarousel: [
      {
        imgSrc: "img/carousel/1.png",
        title: "How To Survive And Thrive In A Coding Bootcamp",
        content: "When it comes to learning code, it takes a village. Learn more about how mentorship underpins the learning experience at Lighthouse Labs.",
        buttonLink: 'https://www.coursecompare.ca/how-to-survive-and-thrive-in-a-coding-bootcamp/',
        btheme: "primary",
        bText: "Learn More"
      },
      {
        imgSrc: "img/carousel/3.png",
        title: "Why This Shopify Dev Mentors Students At Lighthouse Labs",
        content: "Sanjana found career success at Shopify, but this bootcamp alumni returned to Lighthouse Labs to provide the same mentorship she received as a student.",
        buttonLink: 'https://www.coursereport.com/blog/why-this-web-developer-mentors-lighthouse-labs-students',
        btheme: "primary",
        bText: "Learn More"
      },
      {
        imgSrc: "img/carousel/5.png",
        title: "Why Mentorship Is The Backbone Of Our Bootcamp",
        content: "Lighthouse Labs mentors are not your average TA - they're the catalysts for learning and growth in our bootcamp.",
        buttonLink: 'https://www.lighthouselabs.ca/blog/why-mentorship-is-the-backbone-of-our-bootcamp',
        btheme: "primary",
        bText: "Learn More"
      },
      {
        imgSrc: "img/carousel/4.png",
        title: "Our Female Mentors Share Their Insights On Diversity In Development",
        content: "Meet the panel of Lighthouse Labs mentors paving the way for diversity in web development.",
        buttonLink: 'https://www.lighthouselabs.ca/blog/our-female-mentors-share-their-insights-on-diversity-in-development',
        btheme: "primary",
        bText: "Learn More"
      },
      {
        imgSrc: "img/carousel/2.png",
        title: "Why We're Launching A National Initiative To Celebrate Mentorship",
        content: "Our take on Mentorship Month, a dedicated space to shine the spotlight on mentors and promote more intentional mentorship across Canada.",
        buttonLink: 'https://www.lighthouselabs.ca/blog/why-we-re-launching-a-national-initiative-to-celebrate-mentorship',
        btheme: "primary",
        bText: "Learn More"
      }
    ]
  }
}

export { cardb }
