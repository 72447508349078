import React from 'react';
import './styles.scss';

import SocialIcon from '../../Atoms/SocialIcon'

function SocialList(props) {
  const socialItems = props.socialItems;
  const listItems = socialItems.map((item, index) =>
    <SocialIcon
      key={index}
      href={item.linkSrc}
      imgSrc={item.imgSrc}
      alt={item.alt} />
  )
  return (
    <ul className="socialIcons">
      {listItems}
    </ul>
  );
}

export default SocialList;
