import React from 'react';
import './styles.scss';

import Navbar from '../../Organisms/Navbar';

function Header({headerContent}) {
  return (
  <header className='mainHeader'>
    <Navbar navbarContent={headerContent} />
  </header>
  );
}

export default Header;
