import React from 'react';
import './styles.scss';
import Button from '../../Atoms/Button';

function Event(props) {
  const indEvent = props.indDetails;
  return (
    <section className="single__Event">
      <div className="date__Wrap">
        <div className="date__Inner">
          <p className="mon">{indEvent.date[0]}</p>
          <p className="day">{indEvent.date[1]}</p>
        </div>
      </div>
      <div className="main__Wrap">
        <div className="event__Top">
          <h3>{indEvent.title}</h3>
          <p className="eventLocation"><b>Location: </b><a href={indEvent.gmapLink} target="_blank" rel="noopener noreferrer" >{indEvent.location}</a> <b>{indEvent.city}</b></p>
        </div>
        <div className="event__Bottom">
          <div className="event__desc">
            <p>{indEvent.desc ? indEvent.desc : ''}</p>
          </div>
          <div className="event__Link">
            {indEvent.link ? <Button onClick={indEvent.link} theme='golden'>Register</Button> : ''}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Event;
