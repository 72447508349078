import React from 'react';
import './styles.scss';
import Button from '../../Atoms/Button/';

function Centered2Col(props) {
  const sectionContent = props.content;
  return (
  <section className={props.classNames}>
    <div className="inner__Wrap">
      <div>
        <h3>{sectionContent.left.title}</h3>
        <p>{sectionContent.left.paragraph}</p>
        <Button
        theme='primary' onClick={sectionContent.left.button.onClick}>Resources</Button>
      </div>
      <div>
        <h3>{sectionContent.right.title}</h3>
        <p>{sectionContent.right.paragraph}</p>
        <Button
        theme='primary' onClick={sectionContent.right.button.onClick}
        >Events</Button>
      </div>
    </div>
  </section>
  );
}

export default Centered2Col;
