import React from 'react';
import './styles.scss';
import Button from '../../Atoms/Button';

function Card(props) {
  return (
    <div className="Card">
      <img src={props.imgSrc} alt="Card" />
      <h3>{props.title}</h3>
      {props.content ? <p>{props.content}</p> : ''}
      <Button
        onClick={props.buttonOC}
        theme={props.btheme}
      >
      {props.bchildren}
      </Button>
    </div>
  );
}

export default Card;
