import React from 'react';
import './styles.scss';
import NavbarList from '../../Molecules/NavbarList/';
import SocialList from '../../Molecules/SocialList/';
import SponsorshipList from '../../Molecules/SponsorshipWrap';
import Mailchimp from 'react-mailchimp-form'


function Footer({footerContent}) {
  return (
    <footer>
      <div className="footer_inner__Wrap">
        <div className="lhl-logo__Wrap">
          <img src={footerContent.logo.src} alt="Lighthouse Labs Logo"/>
          <a className="golden" href="mailto:hello@lighthouselabs.com">Contact Us</a>
        </div>
        <div className="footerNav__Wrap">
          <NavbarList navBarItems={footerContent.navbar} />
        </div>
        <div className="Social__Wrap">
          <SocialList socialItems={footerContent.socialList} />
          <h3>Sign up for our Newsletter!</h3>
          <Mailchimp
             action='https://lighthouselabs.us10.list-manage.com/subscribe/post?u=e52f4473cc66c663ef6a0b1f4&amp;id=5562be87ae'
             fields={[
               {
                 name: 'EMAIL',
                 placeholder: 'Email',
                 type: 'email',
                 required: true
               }
             ]}
             messages = {
                {
                  sending: "Sending...",
                  success: "Thank you for subscribing!",
                  error: "An unexpected internal error has occurred.",
                  empty: "You must write an e-mail.",
                  duplicate: "Too many subscribe attempts for this email address",
                  button: "Sign Up!"
                }
              }
              className='mailChimp'
             />
        </div>
      </div>
      <div className="footer_Sponsorship__Wrap">
        <div className="sponsorship_wrap__Inner">
          <SponsorshipList sponsorList={footerContent.sponsorList} />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
