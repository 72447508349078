const db = {
  en: {
    heroImage: {
      imgSrc: 'img/events/Events_hero_image.jpg',
      alt: 'Mentorship Month Hero'
    },
    events: {
      Toronto: [
        {
          title:'Lunch && Learn: How to be an Effective Mentor',
          date:['Jan.', '14'],
          location:'Devhub Event Space, 662 King Street West',
          gmapLink: 'https://www.google.com/maps/place/662+King+St+W,+Toronto,+ON/@43.6441789,-79.4043929,17z/data=!3m1!4b1!4m5!3m4!1s0x882b34de2de61291:0xe8fa22738afe6e7b!8m2!3d43.644175!4d-79.4022042',
          city: 'Toronto',
          desc:'Join Lighthouse Labs x Devhub Toronto for this installment of their weekly Lunch&&Learn series: "How to be an Effective Mentor", led by Osh Momoh, Chief Technical Officer at MaRs. Learn what mentorship means, how mentors can impact or change careers, and how to seek out mentors in your network.',
          link:'https://www.meetup.com/Devhub/events/267116284/',
        },
        {  title:'How to mentor workshop ',
          date:['Jan.', '14'],
          location:'Devhub Event Space, 662 King Street West',
          gmapLink: 'https://www.google.com/maps/place/662+King+St+W,+Toronto,+ON/@43.6441789,-79.4043929,17z/data=!3m1!4b1!4m5!3m4!1s0x882b34de2de61291:0xe8fa22738afe6e7b!8m2!3d43.644175!4d-79.4022042',
          city: 'Toronto',
          desc:'Join us for an evening of celebrating mentorship, and learn more about best practices around mentorship vs coaching, career development, and the role of mentorship in the broader tech community.',
          link:'https://www.eventbrite.com/e/85842209359/',
        },
        {
          title:'Mentorship Themed Demo Day',
          date:['Jan.', '16'],
          location:'Devhub Event Space, 662 King Street West',
          gmapLink: 'https://www.google.com/maps/place/662+King+St+W,+Toronto,+ON/@43.6441789,-79.4043929,17z/data=!3m1!4b1!4m5!3m4!1s0x882b34de2de61291:0xe8fa22738afe6e7b!8m2!3d43.644175!4d-79.4022042',
          city: 'Toronto',
          desc:'Come to our Mentorship Themed Demo Day!',
          link:'https://www.eventbrite.com/e/lighthouse-labs-toronto-demo-day-tickets-82831698841',
        },
        {
          title:'The Mentor Meetup!',
          date:['Jan.', '22'],
          location:'Devhub Event Space, 662 King Street West',
          gmapLink: 'https://www.google.com/maps/place/662+King+St+W,+Toronto,+ON/@43.6441789,-79.4043929,17z/data=!3m1!4b1!4m5!3m4!1s0x882b34de2de61291:0xe8fa22738afe6e7b!8m2!3d43.644175!4d-79.4022042',
          city: 'Toronto',
          desc:'Lighthouse Labs and The Mentor Meetup are proud to be partnering and bringing a fantastic meetup for mentor and mentees in the data science, developer, and software communities. To sign up for our special early access tickets just enter "LighthouseLabs" when you register. If you miss out on early access dont worry we will have more launching soon!',
          link:'http://bit.ly/34LtFTM',
        },
        {
          title:'Mentorship Panel',
          date:['Jan.', '29'],
          location:'Devhub Event Space, 662 King Street West',
          gmapLink: 'https://www.google.com/maps/place/662+King+St+W,+Toronto,+ON/@43.6441789,-79.4043929,17z/data=!3m1!4b1!4m5!3m4!1s0x882b34de2de61291:0xe8fa22738afe6e7b!8m2!3d43.644175!4d-79.4022042',
          city: 'Toronto',
          desc:`There is undeniable proof that mentorship can advance your career, whether you're a mentor or mentee. Join the Lighthouse Labs Toronto team as we end off our mentorship month with a panel of industry leaders from companies including Google (and other but TBD) sharing their mentorship insights, including an opportunity for Q&A.`,
          link:'https://www.eventbrite.com/e/85831473247/',
        }
      ],
      Montreal: [
        {
          title:'Mentorship Panel',
          date:['Jan.', '14'],
          location: 'Spaces, 5455 De Gaspé',
          gmapLink: 'https://www.google.com/maps/place/Spaces+-+Quebec,+Montreal+-+Spaces+Mile+End/@45.5273518,-73.5987219,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc91926aaf314b3:0x5c5bed4a32d054fd!8m2!3d45.5273518!4d-73.5965332',
          city: 'Montreal',
          desc: 'Le mentorat a de plus en plus de place dans notre industrie; nous avons rassemblés pour vous un panel de spécialistes de chez Mentorat Québec, Académos et du Réseau M pour discuter de ce levier extraordinaire et nécessaire au développement professionnel',
          link: 'https://panel-mois-du-mentorat-lighthouselabs.eventbrite.com'
        },
        {
          title: 'Mentorship Themed Demo Day',
          date: ['Jan.', '16'],
          location: 'Spaces, 5455 De Gaspé',
          gmapLink: 'https://www.google.com/maps/place/Spaces+-+Quebec,+Montreal+-+Spaces+Mile+End/@45.5273518,-73.5987219,17z/data=!3m1!4b1!4m5!3m4!1s0x4cc91926aaf314b3:0x5c5bed4a32d054fd!8m2!3d45.5273518!4d-73.5965332',
          city: 'Montreal',
          desc: 'In this #MentorshipMonth join us for our traditional celebration of our most recent graduates, we also wish to honour those who contribute to their success: our valued team of mentors.',
          link: 'https://www.eventbrite.com/e/lighthouse-labs-mentorship-panel-tickets-84943266597'
        },
        {
          title:'Forum du Mentorat',
          date:['Jan.', '29'],
          location:'Centre Mont-Royal 2200, rue Mansfield',
          gmapLink: 'https://www.google.com/maps?q=Centre+Mont-Royal+2200,+rue+Mansfield,+Montr%C3%A9al,+QC+H3A+3R8&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjlybb7i6LmAhUBVt8KHTIdCA8Q_AUoAXoECA0QAw',
          city: 'Montreal',
          desc:'Une journée complète en compagnie de 17 intervenants de grande qualité pour nous amener à réfléchir sur comment le mentorat peut aider à aller au-delà des frontières.',
          link:'https://mentoratquebec.org/mois-du-mentorat/programmation-forum-mentorat-2020/'
        }
      ],
      Victoria: [
        {
          title:`The Importance of Mentorship in Victoria's Tech Industry`,
          date:['Jan.', '16'],
          location:'Pixel Union 202-1221 Broad Street',
          gmapLink: 'https://www.google.com/maps/place/Pixel+Union/@48.4260284,-123.3683739,17z/data=!3m1!4b1!4m5!3m4!1s0x548f749a8cf398a1:0x59733c7c65fd8bf9!8m2!3d48.4260284!4d-123.3661852',
          city: 'Victoria',
          desc:'We have rounded up a group of thought leaders to discuss the role vital role mentorship is plays as technology continues to change our workplace. Join us at Pixel Union for a meaningful discussion about mentorship. Snacks and refreshments will be provided!',
          link:'https://www.eventbrite.com/e/the-importance-of-mentorship-in-victorias-tech-industry-tickets-84943266597',
        },
        {
          title: 'So You Think You Can Mentor Victoria',
          date: ['Jan.', '20'],
          location: 'BCDevExchange 1012 Douglas Street, 3rd floor Alliance room',
          gmapLink: 'https://www.google.com/maps/place/1012+Douglas+St,+Victoria,+BC+V8W+2C3/data=!4m2!3m1!1s0x548f749aa7701737:0x30a7860ad590ab7a?sa=X&ved=2ahUKEwjg-MjLqK7mAhVOsZ4KHUGlAK4Q8gEwAHoECAsQAQ',
          city: 'Victoria',
          desc: 'Join us for an evening of celebrating mentorship, and learn more about best practices around mentorship vs coaching, career development, and the role of mentorship in the broader tech community.',
          link: 'https://www.eventbrite.com/e/so-you-think-you-can-mentor-victoria-tickets-85995056529'
        }
      ],
      Vancouver: [
        {
          title:'Becoming a Mentor: Meaning and Impact',
          date:['Jan.', '14'],
          location:'Bench Accounting 200 - 545 Robson St',
          gmapLink: 'https://www.google.com/maps/place/Bench+Accounting/@49.2804094,-123.1202261,17z/data=!3m1!4b1!4m5!3m4!1s0x5486717e504f3dd5:0xf7c8d5f2104a3897!8m2!3d49.2804094!4d-123.1180374',
          city: 'Vancouver',
          desc:'Inspired by National Mentor Month  this panel will create an informative discussion surrounding the meaning of mentorship, why an individual should pursue it, and the impact it can have on your mentee and yourself.',
          link:'https://www.eventbrite.com/e/becoming-a-mentor-meaning-and-impact-tickets-85747339601?aff=MMsite',
        },
        {
          title:'So You Think You Can Mentor',
          date:['Jan.', '21'],
          location: 'Devhub Vancouver, 401 W Georgia St, #600',
          gmapLink: 'https://www.google.com/maps/place/DevHub+Vancouver/@49.2812002,-123.1148434,15z/data=!4m2!3m1!1s0x0:0xe80163c6ca2699?sa=X&ved=2ahUKEwiFmNjJk6jmAhWuJTQIHRzEDngQ_BIwCnoECAsQCA',
          city: 'Vancouver',
          desc: 'Join us for an evening of celebrating mentorship, and learn more about best practices around mentorship vs coaching, career development, and the role of mentorship in the broader tech community.',
          link: 'https://www.eventbrite.com/e/so-you-think-you-can-mentor-tickets-85882106693?aff=MMsite',
        },
        {
          title: 'Lunch && Learn : How to be an effective mentor',
          date:['Jan.', '23'],
          location: 'Devhub Vancouver, 401 W Georgia St, #600',
          gmapLink: 'https://www.google.com/maps/place/DevHub+Vancouver/@49.2812002,-123.1148434,15z/data=!4m2!3m1!1s0x0:0xe80163c6ca2699?sa=X&ved=2ahUKEwiFmNjJk6jmAhWuJTQIHRzEDngQ_BIwCnoECAsQCA',
          city: 'Vancouver',
          desc: 'Join Lighthouse Labs x Devhub Vancouver for this installment of their weekly Lunch&&Learn series: "How to be an Effective Mentor". Learn what mentorship means, how mentors can impact or change careers, and how to seek out mentors in your network.',
          link: 'https://www.meetup.com/DevhubVancouver/events/267116118/?isFirstPublish=true'
        }
      ],
      Calgary: [
        {
          title:'The Importance of Mentorship in tech',
          date:['Jan.', '27'],
          location:'Workhaus CORE, 606 4 St SW, Suite 1100',
          gmapLink: 'https://goo.gl/maps/Wz4hX1Q4z7kxyzsQ7',
          city: 'Calgary',
          desc:'This panel, hosted by Lighthouse Labs, will investigate the role of mentorship in tech with experts from leading Calgary organizations who will provide their insights into why mentorship is vital to the future of work.',
          link:'https://www.eventbrite.com/e/the-importance-of-mentorship-in-tech-tickets-85705151415?aff=MMsite',
        }
      ]
    }
  }
}
export { db }
